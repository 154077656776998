import React from "react";

export default function FaqInfoLinks() {
  return (
    <>
      <a className='email-us-button' href='mailto:info@rafflehouse.com'>
        Email us
      </a>
      <a className='email-info' href='mailto:info@rafflehouse.com'>
        info@rafflehouse.com
      </a>
    </>
  );
}
