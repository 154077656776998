import React, { useRef, useState } from 'react';
import './styles.scss';

const AccordionItem = ({ question, answer, icon, isOpen, onClick, color, id }) => {
  const contentHeight = useRef();
  return (
    <div className={`wrapper`}>
      <h3>
        <button
          id={`accordion-${id}`}
          className={`question-container ${isOpen ? 'active ' : ''}${color ? color : ''}`}
          onClick={onClick}
          aria-controls={`accordion-panel-${id}`}
          aria-expanded={`${isOpen ? 'true' : 'false'}`}
        >
          <span className="question-content">
            {Boolean(icon) && <span className="question-content-icon">{icon}</span>}
            <span>{question}</span>
          </span>
          <span className="question-content-sign">{isOpen ? '-' : '+'}</span>
        </button>
      </h3>

      <div
        id={`accordion-panel-${id}`}
        ref={contentHeight}
        className="answer-container"
        style={isOpen ? { height: contentHeight.current.scrollHeight } : { height: '0px' }}
        role="region"
        aria-labelledby={`accordion-${id}`}
      >
        <p className={`answer-content ${color ? color : ''}`}>{answer}</p>
      </div>
    </div>
  );
};

const Accordion = ({faqs, color}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faq-questions-container">
      {faqs.map((item, index) => (
        <AccordionItem
          id={index}
          color={color}
          key={index}
          question={item.title}
          answer={item.answer}
          icon={item.icon}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
