import React from "react";

export default function BonusDrawBadge() {
  return (
    <div className='bonus-draw__badge'>
      <svg
        width='188'
        height='190'
        viewBox='0 0 188 190'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_7287_6988)'>
          <path
            d='M88.8238 4.02069C91.7495 1.67186 95.9145 1.67186 98.8403 4.02069L111.336 14.0526C113.086 15.4573 115.345 16.0626 117.563 15.721L133.4 13.2811C137.109 12.7098 140.716 14.7923 142.075 18.2893L147.881 33.2252C148.694 35.3165 150.348 36.9703 152.439 37.7832L167.375 43.5891C170.872 44.9484 172.954 48.5555 172.383 52.2636L169.943 68.1015C169.601 70.319 170.207 72.5781 171.611 74.3278L181.643 86.8238C183.992 89.7495 183.992 93.9145 181.643 96.8403L171.611 109.336C170.207 111.086 169.601 113.345 169.943 115.563L172.383 131.4C172.954 135.109 170.872 138.716 167.375 140.075L152.439 145.881C150.348 146.694 148.694 148.348 147.881 150.439L142.075 165.375C140.716 168.872 137.109 170.954 133.4 170.383L117.563 167.943C115.345 167.601 113.086 168.207 111.336 169.611L98.8403 179.643C95.9145 181.992 91.7495 181.992 88.8238 179.643L76.3278 169.611C74.5781 168.207 72.319 167.601 70.1015 167.943L54.2636 170.383C50.5555 170.954 46.9484 168.872 45.5891 165.375L39.7832 150.439C38.9703 148.348 37.3165 146.694 35.2252 145.881L20.2893 140.075C16.7923 138.716 14.7098 135.109 15.2811 131.4L17.721 115.563C18.0626 113.345 17.4573 111.086 16.0526 109.336L6.02069 96.8403C3.67186 93.9145 3.67186 89.7495 6.02069 86.8238L16.0526 74.3278C17.4573 72.5781 18.0626 70.319 17.721 68.1015L15.2811 52.2636C14.7098 48.5555 16.7923 44.9484 20.2893 43.5891L35.2252 37.7832C37.3165 36.9703 38.9703 35.3165 39.7832 33.2252L45.5891 18.2893C46.9484 14.7923 50.5555 12.7098 54.2636 13.2811L70.1015 15.721C72.319 16.0626 74.5781 15.4573 76.3278 14.0526L88.8238 4.02069Z'
            fill='#DEE965'
          />
          <path
            d='M63.5332 107.391C62.345 107.391 61.4539 107.065 60.8598 106.414C60.2658 105.749 59.9687 104.794 59.9687 103.55V98.0164H58V96.6493H59.9687V93.4161H61.6473V96.6493H64.8387V98.0164H61.6473V103.376C61.6473 104.201 61.8131 104.83 62.1447 105.264C62.4763 105.684 63.0151 105.893 63.7611 105.893C63.9822 105.893 64.2032 105.864 64.4243 105.807C64.6453 105.749 64.8456 105.691 65.0252 105.633L65.3154 106.978C65.1358 107.08 64.8733 107.174 64.5279 107.26C64.1825 107.347 63.8509 107.391 63.5332 107.391Z'
            fill='#043B3B'
          />
          <path
            d='M69.9079 107.391C69.2171 107.391 68.5954 107.253 68.0428 106.978C67.504 106.689 67.0757 106.298 66.7579 105.807C66.4402 105.315 66.2813 104.765 66.2813 104.157C66.2813 103.391 66.4678 102.783 66.8408 102.335C67.2276 101.886 67.8563 101.568 68.7266 101.38C69.6108 101.177 70.8128 101.076 72.3325 101.076H72.9957V100.403C72.9957 99.5064 72.8161 98.8627 72.4569 98.4721C72.1115 98.067 71.5519 97.8645 70.7783 97.8645C70.1704 97.8645 69.5832 97.9585 69.0168 98.1466C68.4503 98.3202 67.8701 98.6095 67.276 99.0146L66.6957 97.7343C67.2207 97.3437 67.8563 97.0327 68.6023 96.8012C69.3622 96.5698 70.0875 96.454 70.7783 96.454C72.0769 96.454 73.0371 96.7868 73.6588 97.4522C74.2943 98.1177 74.6121 99.152 74.6121 100.555V107.195H73.0371V105.373C72.7746 105.995 72.3671 106.486 71.8144 106.848C71.2756 107.21 70.6401 107.391 69.9079 107.391ZM70.1773 106.067C71.0062 106.067 71.6832 105.77 72.2082 105.177C72.7332 104.57 72.9957 103.803 72.9957 102.877V102.226H72.3532C71.2342 102.226 70.35 102.284 69.7006 102.4C69.0651 102.501 68.6161 102.689 68.3536 102.964C68.1049 103.224 67.9806 103.586 67.9806 104.049C67.9806 104.642 68.174 105.127 68.5609 105.503C68.9615 105.879 69.5003 106.067 70.1773 106.067Z'
            fill='#043B3B'
          />
          <path
            d='M76.4414 107.195L80.6276 101.749L76.6901 96.6493H78.6796L81.6016 100.534L84.5443 96.6493H86.513L82.5756 101.749L86.7617 107.195H84.7723L81.5808 103.007L78.4309 107.195H76.4414Z'
            fill='#043B3B'
          />
          <path d='M87.9039 102.139V100.685H93.7065V102.139H87.9039Z' fill='#043B3B' />
          <path
            d='M97.3088 107.195V98.0164H95.3193V96.6493H97.3088V96.2804C97.3088 94.8772 97.6404 93.8139 98.3035 93.0906C98.9805 92.3673 100.017 91.9622 101.412 91.8754L102.22 91.832L102.365 93.1774L101.412 93.2425C100.555 93.3148 99.9338 93.5825 99.5469 94.0454C99.1739 94.5083 98.9874 95.181 98.9874 96.0634V96.6493H101.889V98.0164H98.9874V107.195H97.3088Z'
            fill='#043B3B'
          />
          <path
            d='M103.671 107.195V96.6493H105.308V98.5372C105.847 97.2641 106.952 96.5625 108.624 96.4323L109.225 96.3672L109.349 97.8862L108.292 98.0164C107.339 98.1032 106.614 98.4215 106.116 98.9712C105.619 99.5064 105.37 100.244 105.37 101.185V107.195H103.671Z'
            fill='#043B3B'
          />
          <path
            d='M115.515 107.391C113.927 107.391 112.669 106.906 111.744 105.937C110.818 104.953 110.355 103.622 110.355 101.944C110.355 100.859 110.562 99.9043 110.977 99.0797C111.391 98.2406 111.958 97.5969 112.676 97.1484C113.408 96.6855 114.251 96.454 115.204 96.454C116.572 96.454 117.643 96.917 118.417 97.8428C119.19 98.7542 119.577 100.013 119.577 101.619V102.291H111.992C112.048 103.492 112.379 104.411 112.987 105.047C113.595 105.669 114.438 105.98 115.515 105.98C116.123 105.98 116.703 105.886 117.256 105.698C117.809 105.496 118.334 105.17 118.831 104.722L119.411 105.958C118.955 106.407 118.375 106.761 117.671 107.022C116.966 107.268 116.248 107.391 115.515 107.391ZM115.246 97.756C114.293 97.756 113.54 98.067 112.987 98.6891C112.434 99.3111 112.11 100.128 112.013 101.141H118.126C118.085 100.071 117.816 99.2388 117.318 98.6457C116.835 98.0526 116.144 97.756 115.246 97.756Z'
            fill='#043B3B'
          />
          <path
            d='M126.545 107.391C124.956 107.391 123.699 106.906 122.773 105.937C121.848 104.953 121.385 103.622 121.385 101.944C121.385 100.859 121.592 99.9043 122.006 99.0797C122.421 98.2406 122.987 97.5969 123.706 97.1484C124.438 96.6855 125.281 96.454 126.234 96.454C127.602 96.454 128.672 96.917 129.446 97.8428C130.22 98.7542 130.607 100.013 130.607 101.619V102.291H123.022C123.077 103.492 123.409 104.411 124.017 105.047C124.624 105.669 125.467 105.98 126.545 105.98C127.153 105.98 127.733 105.886 128.286 105.698C128.838 105.496 129.363 105.17 129.861 104.722L130.441 105.958C129.985 106.407 129.405 106.761 128.7 107.022C127.996 107.268 127.277 107.391 126.545 107.391ZM126.275 97.756C125.322 97.756 124.569 98.067 124.017 98.6891C123.464 99.3111 123.139 100.128 123.043 101.141H129.156C129.115 100.071 128.845 99.2388 128.348 98.6457C127.864 98.0526 127.173 97.756 126.275 97.756Z'
            fill='#043B3B'
          />
          <path
            d='M47.7655 129.489C47.0483 129.489 46.4028 129.348 45.829 129.066C45.2695 128.769 44.8248 128.368 44.4949 127.864C44.165 127.359 44 126.795 44 126.172C44 125.385 44.1937 124.762 44.581 124.302C44.9826 123.842 45.6353 123.515 46.539 123.323C47.4571 123.115 48.7051 123.011 50.283 123.011H50.9716V122.321C50.9716 121.401 50.7851 120.74 50.4121 120.34C50.0535 119.924 49.4725 119.716 48.6692 119.716C48.0381 119.716 47.4284 119.813 46.8403 120.006C46.2521 120.184 45.6497 120.481 45.0328 120.896L44.4303 119.583C44.9754 119.182 45.6353 118.863 46.4099 118.626C47.1989 118.388 47.952 118.269 48.6692 118.269C50.0176 118.269 51.0146 118.611 51.6601 119.293C52.32 119.976 52.6499 121.037 52.6499 122.477V129.288H51.0146V127.419C50.7421 128.057 50.3189 128.561 49.7451 128.932C49.1856 129.303 48.5258 129.489 47.7655 129.489ZM48.0452 128.131C48.9059 128.131 49.6088 127.827 50.1539 127.218C50.699 126.595 50.9716 125.808 50.9716 124.859V124.191H50.3045C49.1426 124.191 48.2245 124.25 47.5503 124.369C46.8905 124.473 46.4243 124.666 46.1517 124.948C45.8935 125.215 45.7644 125.586 45.7644 126.061C45.7644 126.669 45.9652 127.166 46.3669 127.552C46.7829 127.938 47.3423 128.131 48.0452 128.131Z'
            fill='#043B3B'
          />
          <path
            d='M59.0894 129.489C58.0279 129.489 57.2246 129.17 56.6795 128.532C56.1488 127.879 55.8834 126.936 55.8834 125.704V113.595H57.6263V125.571C57.6263 127.159 58.2359 127.953 59.4552 127.953C59.8139 127.953 60.1366 127.908 60.4235 127.819L60.3805 129.333C59.9358 129.437 59.5054 129.489 59.0894 129.489Z'
            fill='#043B3B'
          />
          <path
            d='M66.257 129.489C65.0234 129.489 64.0981 129.155 63.4813 128.487C62.8645 127.804 62.5561 126.825 62.5561 125.549V119.872H60.5119V118.47H62.5561V115.153H64.299V118.47H67.6126V119.872H64.299V125.371C64.299 126.216 64.4711 126.862 64.8154 127.307C65.1597 127.738 65.7191 127.953 66.4937 127.953C66.7232 127.953 66.9528 127.923 67.1823 127.864C67.4118 127.804 67.6198 127.745 67.8063 127.686L68.1075 129.066C67.921 129.17 67.6485 129.266 67.2899 129.355C66.9312 129.444 66.587 129.489 66.257 129.489Z'
            fill='#043B3B'
          />
          <path
            d='M74.191 129.489C72.5413 129.489 71.236 128.992 70.2749 127.997C69.3138 126.988 68.8332 125.623 68.8332 123.901C68.8332 122.788 69.0484 121.809 69.4787 120.963C69.9091 120.102 70.4972 119.442 71.2431 118.982C72.0034 118.507 72.8785 118.269 73.8682 118.269C75.2884 118.269 76.4001 118.744 77.2034 119.694C78.0067 120.629 78.4084 121.92 78.4084 123.567V124.258H70.5331C70.5905 125.489 70.9347 126.432 71.5659 127.085C72.1971 127.723 73.0721 128.042 74.191 128.042C74.8222 128.042 75.4247 127.945 75.9984 127.752C76.5722 127.545 77.1173 127.211 77.6338 126.751L78.2362 128.02C77.7629 128.48 77.1604 128.843 76.4288 129.11C75.6972 129.363 74.9513 129.489 74.191 129.489ZM73.9113 119.605C72.9215 119.605 72.1397 119.924 71.5659 120.562C70.9921 121.2 70.655 122.039 70.5546 123.078H76.9022C76.8591 121.98 76.5794 121.126 76.063 120.518C75.5609 119.909 74.8437 119.605 73.9113 119.605Z'
            fill='#043B3B'
          />
          <path
            d='M80.9308 129.288V118.47H82.6306V120.406C83.1901 119.1 84.3377 118.381 86.0734 118.247L86.6974 118.18L86.8265 119.739L85.7291 119.872C84.7393 119.961 83.9862 120.288 83.4698 120.852C82.9534 121.401 82.6952 122.158 82.6952 123.122V129.288H80.9308Z'
            fill='#043B3B'
          />
          <path
            d='M88.6425 129.288V118.47H90.3424V120.317C90.6866 119.635 91.1744 119.123 91.8055 118.781C92.4367 118.44 93.1468 118.269 93.9357 118.269C96.4174 118.269 97.6582 119.694 97.6582 122.543V129.288H95.9153V122.655C95.9153 121.646 95.7217 120.911 95.3343 120.451C94.9614 119.976 94.3589 119.739 93.5269 119.739C92.5658 119.739 91.7984 120.05 91.2246 120.674C90.6651 121.282 90.3854 122.098 90.3854 123.122V129.288H88.6425Z'
            fill='#043B3B'
          />
          <path
            d='M104.164 129.489C103.447 129.489 102.801 129.348 102.228 129.066C101.668 128.769 101.223 128.368 100.894 127.864C100.564 127.359 100.399 126.795 100.399 126.172C100.399 125.385 100.592 124.762 100.98 124.302C101.381 123.842 102.034 123.515 102.938 123.323C103.856 123.115 105.104 123.011 106.682 123.011H107.37V122.321C107.37 121.401 107.184 120.74 106.811 120.34C106.452 119.924 105.871 119.716 105.068 119.716C104.437 119.716 103.827 119.813 103.239 120.006C102.651 120.184 102.048 120.481 101.431 120.896L100.829 119.583C101.374 119.182 102.034 118.863 102.809 118.626C103.598 118.388 104.351 118.269 105.068 118.269C106.416 118.269 107.413 118.611 108.059 119.293C108.719 119.976 109.049 121.037 109.049 122.477V129.288H107.413V127.419C107.141 128.057 106.718 128.561 106.144 128.932C105.584 129.303 104.924 129.489 104.164 129.489ZM104.444 128.131C105.305 128.131 106.007 127.827 106.553 127.218C107.098 126.595 107.37 125.808 107.37 124.859V124.191H106.703C105.541 124.191 104.623 124.25 103.949 124.369C103.289 124.473 102.823 124.666 102.55 124.948C102.292 125.215 102.163 125.586 102.163 126.061C102.163 126.669 102.364 127.166 102.766 127.552C103.182 127.938 103.741 128.131 104.444 128.131Z'
            fill='#043B3B'
          />
          <path
            d='M116.478 129.489C115.244 129.489 114.319 129.155 113.702 128.487C113.085 127.804 112.777 126.825 112.777 125.549V119.872H110.733V118.47H112.777V115.153H114.52V118.47H117.833V119.872H114.52V125.371C114.52 126.216 114.692 126.862 115.036 127.307C115.38 127.738 115.94 127.953 116.715 127.953C116.944 127.953 117.174 127.923 117.403 127.864C117.633 127.804 117.841 127.745 118.027 127.686L118.328 129.066C118.142 129.17 117.869 129.266 117.511 129.355C117.152 129.444 116.808 129.489 116.478 129.489Z'
            fill='#043B3B'
          />
          <path
            d='M119.589 115.776V113.751H121.741V115.776H119.589ZM119.805 129.288V118.47H121.548V129.288H119.805Z'
            fill='#043B3B'
          />
          <path
            d='M127.883 129.288L123.386 118.47H125.279L128.743 127.24L132.251 118.47H134.037L129.518 129.288H127.883Z'
            fill='#043B3B'
          />
          <path
            d='M140.445 129.489C138.795 129.489 137.49 128.992 136.529 127.997C135.567 126.988 135.087 125.623 135.087 123.901C135.087 122.788 135.302 121.809 135.732 120.963C136.163 120.102 136.751 119.442 137.497 118.982C138.257 118.507 139.132 118.269 140.122 118.269C141.542 118.269 142.654 118.744 143.457 119.694C144.26 120.629 144.662 121.92 144.662 123.567V124.258H136.787C136.844 125.489 137.188 126.432 137.82 127.085C138.451 127.723 139.326 128.042 140.445 128.042C141.076 128.042 141.678 127.945 142.252 127.752C142.826 127.545 143.371 127.211 143.887 126.751L144.49 128.02C144.017 128.48 143.414 128.843 142.682 129.11C141.951 129.363 141.205 129.489 140.445 129.489ZM140.165 119.605C139.175 119.605 138.393 119.924 137.82 120.562C137.246 121.2 136.909 122.039 136.808 123.078H143.156C143.113 121.98 142.833 121.126 142.317 120.518C141.815 119.909 141.097 119.605 140.165 119.605Z'
            fill='#043B3B'
          />
          <path
            d='M47 74.418C49.0942 74.418 49.8421 73.7794 50.1579 72.0276L50.4072 70.6032H47V65.5604H51.288L51.5706 63.9886C52.5678 58.2745 56.7229 55 65.0995 55C66.1964 55 67.3598 55.0491 68.5897 55.1637V60.9924C67.8584 60.9105 66.9443 60.8614 66.2629 60.8614C61.7422 60.8614 60.3128 62.2858 59.7477 65.1183L59.6646 65.5604H64.2851V70.6032H58.6508C58.3683 71.9293 58.0192 72.7643 57.0386 73.583H70.5842V79.3134H47V74.418Z'
            fill='#043B3B'
          />
          <path
            d='M82.3846 79.8373C75.9691 79.8373 71.731 77.2832 71.6811 72.1094H80.4566C80.4732 73.6648 81.138 74.7782 82.4677 74.7782C83.8804 74.7782 84.5784 73.5339 84.5784 71.2253C84.5784 68.8513 83.8305 67.918 82.4178 67.918C81.1547 67.918 80.3236 68.753 80.1408 69.8336H72.3625V55.835H92.2404V61.1725H80.5065V65.282C81.5369 63.874 83.6311 62.8098 86.3568 62.8098C90.1795 62.8098 93.437 65.5767 93.437 70.685C93.437 77.2668 88.7335 79.8373 82.3846 79.8373Z'
            fill='#043B3B'
          />
          <path
            d='M105.753 79.8373C99.3372 79.8373 95.0991 77.2832 95.0492 72.1094H103.825C103.841 73.6648 104.506 74.7782 105.836 74.7782C107.248 74.7782 107.947 73.5339 107.947 71.2253C107.947 68.8513 107.199 67.918 105.786 67.918C104.523 67.918 103.692 68.753 103.509 69.8336H95.7306V55.835H115.608V61.1725H103.875V65.282C104.905 63.874 106.999 62.8098 109.725 62.8098C113.548 62.8098 116.805 65.5767 116.805 70.685C116.805 77.2668 112.102 79.8373 105.753 79.8373Z'
            fill='#043B3B'
          />
          <path
            d='M130.916 79.3134L127.093 71.078V79.3134H118.866V55.835H127.093V68.442L130.866 61.2052H139.924L134.988 69.4407L140.14 79.3134H130.916Z'
            fill='#043B3B'
          />
          <path
            d='M88.8854 43C87.4884 43 86.2695 42.6957 85.2289 42.0871C84.2025 41.4647 83.4042 40.5934 82.834 39.473C82.278 38.3527 82 37.0249 82 35.4896C82 33.9544 82.278 32.6266 82.834 31.5062C83.3899 30.3859 84.1811 29.5214 85.2075 28.9129C86.2482 28.3043 87.4741 28 88.8854 28C90.311 28 91.537 28.3043 92.5634 28.9129C93.604 29.5214 94.4024 30.3859 94.9583 31.5062C95.5286 32.6127 95.8137 33.9336 95.8137 35.4689C95.8137 37.0041 95.5286 38.3389 94.9583 39.473C94.3881 40.5934 93.5827 41.4647 92.542 42.0871C91.5156 42.6957 90.2968 43 88.8854 43ZM88.8854 41.4855C90.4963 41.4855 91.7437 40.9599 92.6275 39.9087C93.5114 38.8575 93.9533 37.3845 93.9533 35.4896C93.9533 33.5947 93.5114 32.1286 92.6275 31.0913C91.7437 30.0401 90.4963 29.5145 88.8854 29.5145C87.3031 29.5145 86.07 30.0401 85.1861 31.0913C84.3023 32.1286 83.8604 33.5947 83.8604 35.4896C83.8604 37.3707 84.3023 38.8437 85.1861 39.9087C86.07 40.9599 87.3031 41.4855 88.8854 41.4855Z'
            fill='#043B3B'
          />
          <path
            d='M99.141 42.8133V32.7303H100.83V34.5353C101.386 33.3181 102.527 32.6473 104.252 32.5228L104.872 32.4606L105 33.9129L103.909 34.0373C102.926 34.1203 102.177 34.4246 101.664 34.9502C101.151 35.462 100.894 36.1674 100.894 37.0664V42.8133H99.141Z'
            fill='#043B3B'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_7287_6988'
            x='0.257812'
            y='2.25928'
            width='187.148'
            height='187.146'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='4' />
            <feGaussianBlur stdDeviation='2' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7287_6988' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_7287_6988'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
