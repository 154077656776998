import React from "react";
import { useMobileDevice } from "../../../hooks/useMobileDevice";
import VerticalTimeline from "../VerticalTimeline/VerticalTimeline";
import HorizontalTimeline from "../HorizontalTimeline/HorizontalTimeline";

export default function TimelineSteps({ verticalTimelineSteps, horizontalTimelineSteps, widget }) {
  const { matches: mobile } = useMobileDevice();

  return mobile ? (
    <HorizontalTimeline steps={horizontalTimelineSteps} />
  ) : (
    <VerticalTimeline steps={verticalTimelineSteps} widget={widget} />
  );
}
