import React from 'react';

export default function DreamDrawImg({children}) {
  return (
    <div className="dream-draw__media">
      <div className="dream-draw-img">
        {children}
        <p className="dream-draw-img__text">
          Win this £2.7m Kent Dream Home or choose the same value in cash, tax-free
        </p>
      </div>
    </div>
  );
}
