import React from "react";

export default function HorizontalTimelineItem({ date, info, icon }) {
  return (
    <div className='horizontal-timeline-item'>
      <div className='horizontal-timeline-item__info'>{info}</div>
      <div className='horizontal-timeline-item__icon'>{icon}</div>
      <div className='horizontal-timeline-item__point'>
        <span></span>
      </div>
      <div className='horizontal-timeline-item__date'>{date}</div>
    </div>
  );
}
