import React from "react";

// type = "single" | "list"
export default function CharityContent({ type = "single", children }) {
  return (
    <div className={`rui-charities-content rui-charities-content_${type}`}>
      <div className='rui-left-side'>
        <p className='rui-title'>
          Your donation.
          <br /> <span>Your choice.</span>
        </p>
        <p className='rui-sub'>
          Win a home and donate to our charity partner.
        </p>
      </div>
      {children}
    </div>
  );
}
