import React from "react";
import DreamDrawItem from "./dream-draw-item/dream-draw-item";
import TitleText from "../../common/title-text/title-text";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function DreamDrawContent({ title, features, charity, children }) {
  const matchesLg = useMediaQuery("(max-width: 960px)");
  return (
    <div className='dream-draw__content'>
      <div className='dream-draw-cols'>
        <div className='dream-draw__items'>
          {Boolean(matchesLg) && (
            <TitleText isCentered as='h1' variant={"primary"} color='color-5'>
              {title}
            </TitleText>
          )}
          {charity || null}
          <h2>Key highlights</h2>
          <div className='dream-draw__items-grid'>
            {features.map((item, index) => (
              <DreamDrawItem key={index} {...item} />
            ))}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
