import React from "react";
import "../styles.scss";

export default function TestimonialsContainer({ children }) {
  return (
    <div className='testimonials-block'>
      {children}
    </div>
  );
}
